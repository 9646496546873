import { isEmpty } from "lodash-es";
import { forwardRef } from "react";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import { NextLink, type NextLinkProps } from "@ui/components/navigation/link/NextLink";

interface IInternalLinkProps extends Partial<NextLinkProps> {
    link: ILink | IPagePath;
}

export const InternalLink = forwardRef<HTMLAnchorElement, IInternalLinkProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as ILink;
        const fields = _link?.fields;
        const internalLinkOnNewTab = fields?.internalLinkOnNewTab;
        const href = fields?.internalLink?.fields?.completeSlug || "";
        const label = fields?.label || "";
        // We double check if the deepLink is not empty and the href is empty, otherwise will have prevalence the href
        const isDeepLink = !isEmpty(fields?.deepLink) && isEmpty(href);
        const handleDeepLink = (e) => {
            e.preventDefault();
            if (isDeepLink) {
                const element = document?.querySelector(`#${fields.deepLink}`);
                const dimensions = element?.getBoundingClientRect();
                const offset = 120;
                window.scrollTo({
                    top: dimensions?.top - offset,
                    behavior: "smooth",
                });
            }
        };

        const target = internalLinkOnNewTab ? "_blank" : "_self";

        return isDeepLink ? (
            <NextLink
                data-testid={fields?.internalLink?.fields?.pageTypeId}
                href={`#${fields.deepLink}`}
                {...rest}
                ref={ref}
                onClick={(e) => handleDeepLink(e)}
            >
                {isEmpty(children) ? label : children}
            </NextLink>
        ) : (
            <NextLink
                data-testid={fields?.internalLink?.fields?.pageTypeId}
                href={href}
                target={target}
                _hover={{ color: "gray.500" }}
                {...rest}
                ref={ref}
            >
                {isEmpty(children) ? label : children}
            </NextLink>
        );
    }
);

InternalLink.displayName = "InternalLink";
