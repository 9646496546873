import { useGlobalModalContext } from "@components/GlobalModalProvider";
import { defaultUnderlineStyle } from "@ui/css/common";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import { styles } from "@components/commerce/baseForms/baseFormStyles";
import { getCountryCode, gottenConfig } from "@config/site/site-config";
import { CommonCMS, commonReplacements } from "@lib/constants/contentful";
import { pluralizeHelper, useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { ModalTypes } from "@lib/enums/ModalTypes";
import FetchService from "@lib/helpers/fetchService";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import type { ContactFormErrorHeaders } from "@lib/validators/contact-form/contactFormValidationSchema";
import { getContactFormValidationSchema } from "@lib/validators/contact-form/contactFormValidationSchema";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Field } from "@ui/components/forms/field/Field";
import { FormControl } from "@ui/components/forms/form-control/FormControl";
import { FormErrorMessage } from "@ui/components/forms/form-control/FormError";
import { FormLabel } from "@ui/components/forms/form-control/FormLabel";
import { Input } from "@ui/components/forms/input/Input";
import { Select } from "@ui/components/forms/select/Select";
import { Textarea } from "@ui/components/forms/textarea/Textarea";
import { Box } from "@ui/components/layout/box/Box";
import { ModalFooter } from "@ui/components/overlay/modal/Modal";
import { Form, Formik } from "formik";
import { useRouter } from "next/router";
import { useState } from "react";
import Modal from "../Modal";
import ModalContent from "../ModalContent";
import { validateRecaptcha } from "@lib/utils/validateRecaptcha";

export const ContactForm = ({ contactFormAgreement, reCaptchaAgreement }) => {
    const { get: getMicrocopy } = useMicrocopy();

    const headers = {
        email: getMicrocopy(CommonCMS.global, CommonCMS.email),
        firstName: getMicrocopy(CommonCMS.global, CommonCMS.firstName),
        lastName: getMicrocopy(CommonCMS.global, CommonCMS.lastName),
        phone: getMicrocopy(CommonCMS.global, CommonCMS.phone),
        country: getMicrocopy(CommonCMS.global, CommonCMS.country),
        myQuestion: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.myQuestion),
        orderNumber: getMicrocopy(CommonCMS.global, CommonCMS.orderNumber),
        comment: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.comment),
        title: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.title),
        submitLabel: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.submitLabel),
    };

    const placeholders = {
        email: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.email),
        firstName: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.firstName),
        lastName: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.lastName),
        phone: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.phone),
        myQuestion: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.myQuestion),
        orderNumber: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.orderNumber),
        comment: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.placeholders.comment),
    };

    const headerErrors: ContactFormErrorHeaders = {
        firstName: getMicrocopy(CommonCMS.global, CommonCMS.error.firstName),
        lastName: getMicrocopy(CommonCMS.global, CommonCMS.error.lastName),
        email: getMicrocopy(CommonCMS.global, CommonCMS.error.email),
        phoneRequirements: getMicrocopy(CommonCMS.global, CommonCMS.error.phone),
        orderNumberRequirements: getMicrocopy(CommonCMS.global, CommonCMS.error.orderNumber),
        requiredField: getMicrocopy(CommonCMS.global, CommonCMS.error.requiredFieldError),
        maxEmailCharacters: getMicrocopy(CommonCMS.global, CommonCMS.error.maxCharacters, {
            replacements: commonReplacements.maxEmailCharacters,
        }),
        maxPhoneCharacters: getMicrocopy(CommonCMS.global, CommonCMS.error.maxCharacters, {
            replacements: commonReplacements.maxPhoneCharacters,
        }),
        countryCode: getMicrocopy(CommonCMS.global, CommonCMS.error.countryCode, {
            replacements: commonReplacements.maxPhoneCharacters,
        }),
    };

    const myQuestionOptions = [
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.cancelOrder),
            value: "Cancel/Change Order",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.generalInfo),
            value: "General Information",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.laceRequest),
            value: "Lace/Insole Request",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.myAccount),
            value: "My Account",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.orderStatus),
            value: "Order Status",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.other),
            value: "Other",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.productInfo),
            value: "Product Information",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.return),
            value: "Return",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.exchange),
            value: "Exchange",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.shoeCare),
            value: "Shoe Care",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.warranty),
            value: "Warranty Claim",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.website),
            value: "Website Issue",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.refunds),
            value: "Refunds",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.promo),
            value: "Promotion/Discount Information",
        },
    ];

    const { hideModal, showModal } = useGlobalModalContext();

    const router = useRouter();
    const basePath = router?.basePath || "";

    const countryCode = getCountryCode;

    const [isLoading, setIsLoading] = useState(false);

    const errorToast = useErrorToast(2000);

    const errorToastHeader = getMicrocopy(CommonCMS.global, CommonCMS.error.general.title);
    const errorToastDescription = getMicrocopy(
        CommonCMS.global,
        CommonCMS.error.general.description
    );

    return (
        <Modal closeOnOverlayClick={true} onClose={() => hideModal()}>
            <Formik
                validationSchema={getContactFormValidationSchema(headerErrors)}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    countryCode: countryCode,
                    orderNumber: "",
                    comment: "",
                    myQuestion: "",
                }}
                onSubmit={async (values) => {
                    setIsLoading(true);
                    try {
                        const { consentVersions } = await FetchService.get<{
                            consentVersions: { [x: string]: any };
                        }>(`${basePath}/api/get-consent-versions`);
                        const serviceCloudToken = await FetchService.get(
                            `${basePath}/api/contact-form/get-service-cloud-token`
                        );

                        await validateRecaptcha({
                            shouldCheck: gottenConfig.reCaptchaIntegration.contactForm,
                            onSuccess: async () => {
                                const { country, language } = gottenConfig;

                                await FetchService.post(`${basePath}/api/contact-form`, {
                                    ...values,
                                    orderNumber: values.orderNumber.replaceAll("#", ""),
                                    token: serviceCloudToken,
                                    consentVersions,
                                    country,
                                    language,
                                });
                                setIsLoading(false);

                                hideModal();
                                showModal(ModalTypes.contactFormSubmitted);
                            },
                            onAbort: () => {
                                setIsLoading(false);
                                errorToast({
                                    title: errorToastHeader,
                                    description: errorToastDescription,
                                });
                            },
                            onError: () => {
                                setIsLoading(false);
                                errorToast({
                                    title: errorToastHeader,
                                    description: errorToastDescription,
                                });
                            },
                            action: "SUBMIT_CONTACT_FORM",
                        });
                    } catch (e) {
                        setIsLoading(false);
                        errorToast({
                            title: errorToastHeader,
                            description: errorToastDescription,
                        });
                    }
                }}
            >
                {({ errors, touched, setFieldValue, values }) => {
                    return (
                        <ModalContent header={headers.title}>
                            <Form noValidate={true} id={"contactForm"}>
                                <Box data-testid="ContactForm">
                                    <Text
                                        sx={{
                                            "& button": {
                                                color: "black",
                                                ...defaultUnderlineStyle,
                                            },
                                        }}
                                        color="black"
                                        fontSize={[
                                            "mobileBodyTextSmall",
                                            "mobileBodyTextSmall",
                                            "desktopBodyTextSmall",
                                        ]}
                                    >
                                        {getMicrocopy(
                                            CommonCMS.global,
                                            CommonCMS.contactForm.description
                                        )}
                                    </Text>
                                </Box>
                                <Box>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            (!!errors.firstName && touched.firstName) as boolean
                                        }
                                    >
                                        <FormLabel htmlFor={"firstName"}>
                                            {headers.firstName}
                                        </FormLabel>
                                        <Field
                                            as={Input}
                                            id={"firstName"}
                                            name={"firstName"}
                                            type={"firstName"}
                                            placeholder={placeholders.firstName}
                                        />
                                        <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            (!!errors.lastName && touched.lastName) as boolean
                                        }
                                    >
                                        <FormLabel htmlFor={"lastName"}>
                                            {headers.lastName}
                                        </FormLabel>
                                        <Field
                                            as={Input}
                                            id={"lastName"}
                                            name={"lastName"}
                                            type={"lastName"}
                                            placeholder={placeholders.lastName}
                                        />
                                        <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={(!!errors.email && touched.email) as boolean}
                                    >
                                        <FormLabel htmlFor={"email"}>{headers.email}</FormLabel>
                                        <Field
                                            as={Input}
                                            id={"email"}
                                            name={"email"}
                                            type={"email"}
                                            placeholder={placeholders.email}
                                        />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        <FormControl
                                            width={120}
                                            isInvalid={
                                                (!!errors.countryCode &&
                                                    touched.countryCode) as boolean
                                            }
                                        >
                                            <FormLabel htmlFor="countryCode" sx={styles.formLabel}>
                                                {headers.country}
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                id="countryCode"
                                                name="countryCode"
                                                type="text"
                                                sx={styles.countryCodeField}
                                                width={120}
                                            />
                                            <FormErrorMessage>
                                                {errors.countryCode}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            isRequired
                                            isInvalid={(!!errors.phone && touched.phone) as boolean}
                                        >
                                            <FormLabel htmlFor={"phone"}>{headers.phone}</FormLabel>
                                            <Field
                                                as={Input}
                                                id={"phone"}
                                                name={"phone"}
                                                type={"phone"}
                                                placeholder={placeholders.phone}
                                            />
                                            <FormErrorMessage>{errors.phone}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            (!!errors.orderNumber && touched.orderNumber) as boolean
                                        }
                                    >
                                        <FormLabel htmlFor={"orderNumber"}>
                                            {headers.orderNumber}
                                        </FormLabel>
                                        <Field
                                            as={Input}
                                            id={"orderNumber"}
                                            name={"orderNumber"}
                                            type={"orderNumber"}
                                            placeholder={placeholders.orderNumber}
                                        />
                                        <FormErrorMessage>{errors.orderNumber}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            (!!errors.myQuestion && touched.myQuestion) as boolean
                                        }
                                    >
                                        <FormLabel htmlFor={"myQuestion"}>
                                            {headers.myQuestion}
                                        </FormLabel>
                                        <Select
                                            id={"myQuestion"}
                                            name={"myQuestion"}
                                            onChange={(ev) => {
                                                setFieldValue("myQuestion", ev.target.value);
                                            }}
                                            height={["9.6", "10.4"]}
                                            sx={{ color: values.myQuestion ? "" : "lightGray" }}
                                            required
                                        >
                                            <option selected hidden disabled value="">
                                                {placeholders.myQuestion}
                                            </option>
                                            {myQuestionOptions
                                                .filter(({ label, value }) => label && value)
                                                .map(({ label, value }) => (
                                                    <option
                                                        key={value}
                                                        value={value}
                                                        style={{ color: "black" }}
                                                    >
                                                        {label}
                                                    </option>
                                                ))}
                                        </Select>
                                        <FormErrorMessage>{errors.myQuestion}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={(!!errors.comment && touched.comment) as boolean}
                                    >
                                        <FormLabel htmlFor={"comment"}>{headers.comment}</FormLabel>
                                        <Field
                                            as={Textarea}
                                            id={"comment"}
                                            name={"comment"}
                                            type={"comment"}
                                            placeholder={placeholders.comment}
                                            multiple={true}
                                        />
                                        <FormErrorMessage>{errors.comment}</FormErrorMessage>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Text>
                                                {getMicrocopy(
                                                    CommonCMS.global,
                                                    CommonCMS.contactForm.commentCharacters,
                                                    {
                                                        replacements: {
                                                            current: values.comment.length,
                                                            left: 1000 - values.comment.length,
                                                        },
                                                        pluralize: pluralizeHelper(
                                                            values.comment.length
                                                        ),
                                                    }
                                                )}
                                            </Text>
                                        </Box>
                                    </FormControl>
                                </Box>
                            </Form>
                            <ModalFooter>
                                <Box>
                                    <Button
                                        w={"100%"}
                                        type={"submit"}
                                        form={"contactForm"}
                                        data-testid="ContactForm-button"
                                        isLoading={isLoading}
                                    >
                                        <span>{headers.submitLabel}</span>
                                    </Button>
                                    <Text
                                        sx={{
                                            mt: 3,
                                            "& button": {
                                                color: "black",
                                                ...defaultUnderlineStyle,
                                            },
                                        }}
                                        color="black"
                                        fontSize={[
                                            "mobileBodyTextSmall",
                                            "mobileBodyTextSmall",
                                            "desktopBodyTextSmall",
                                        ]}
                                        alignContent={"center"}
                                    >
                                        <span>
                                            {renderRichTextToComponent(contactFormAgreement)}
                                        </span>
                                    </Text>
                                    <Text
                                        sx={{
                                            mt: 3,
                                            "& button": {
                                                ...defaultUnderlineStyle,
                                                color: "black",
                                            },
                                        }}
                                        color="black"
                                        fontSize={[
                                            "mobileBodyTextSmall",
                                            "mobileBodyTextSmall",
                                            "desktopBodyTextSmall",
                                        ]}
                                        alignContent={"center"}
                                    >
                                        {renderRichTextToComponent(reCaptchaAgreement)}
                                    </Text>
                                </Box>
                            </ModalFooter>
                        </ModalContent>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default ContactForm;
